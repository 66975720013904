const landObject = {
  National: {
    area: 923769,
    position: '9.0820° N, 8.6753° E',
  },
  Niger: {
    area: 76363,
    position: '10°00′N 6°00′E',
  },
  Borno: {
    area: 70898,
    position: '11°30′N 13°00′E',
  },
  Taraba: {
    area: 54473,
    position: '8° 0′ 0″ N, 10° 30′ 0″ E',
  },
  Kaduna: {
    area: 46053,
    position: '10°20′N 7°45′E',
  },
  Bauchi: {
    area: 49119,
    position: '10°30′N 10°00′E',
  },
  Yobe: {
    area: 45502,
    position: '12°0′0″N, 11°30′0″E',
  },
  Zamfara: {
    area: 39762,
    position: '12°10′N 6°15′E',
  },
  Adamawa: {
    area: 36917,
    position: '9°20′N 12°30′E',
  },
  Kwara: {
    area: 36825,
    position: '8°30′N 5°00′E',
  },
  Kebbi: {
    area: 36800,
    position: '11°30′N 4°00′E',
  },
  Benue: {
    area: 34059,
    position: '7.3369° N, 8.7404° E',
  },
  Plateau: {
    area: 30913,
    position: '9°10′N 9°45′E',
  },
  Kogi: {
    area: 29833,
    position: '7°30′N 6°42′E',
  },
  Oyo: {
    area: 28454,
    position: '8°00′N 4°00′E',
  },
  Nasarawa: {
    area: 27117,
    position: '8°32′N 8°18′E',
  },
  Sokoto: {
    area: 25973,
    position: '13°05′N 05°15′E',
  },
  Katsina: {
    area: 24192,
    position: '12°15′N 7°30′E',
  },
  Jigawa: {
    area: 23154,
    position: '12°00′N 9°45′E',
  },
  'Cross River': {
    area: 20156,
    position: '5°45′N 8°30′E',
  },
  Kano: {
    area: 20131,
    position: '11.7471° N, 8.5247° E',
  },
  Gombe: {
    area: 18768,
    position: '10.3638° N, 11.1928° E',
  },
  Edo: {
    area: 17802,
    position: '6°30′N 6°00′E',
  },
  Delta: {
    area: 17698,
    position: '5.7040° N, 5.9339° E',
  },
  Ogun: {
    area: 16762,
    position: '6.9980° N, 3.4737° E',
  },
  Ondo: {
    area: 15500,
    position: '6.9149° N, 5.1478° E',
  },
  Rivers: {
    area: 11077,
    position: '4.8396° N, 6.9112° E',
  },
  Bayelsa: {
    area: 10773,
    position: '4.7719° N, 6.0699° E',
  },
  Osun: {
    area: 9251,
    position: '7.5629° N, 4.5200° E',
  },
  FCT: {
    area: 7315,
    position: '8.8941° N, 7.1860° E',
  },
  Enugu: {
    area: 7161,
    position: '6.5364° N, 7.4356° E',
  },
  'Akwa Ibom': {
    area: 7081,
    position: '4.9057° N, 7.8537° E',
  },
  Ekiti: {
    area: 6353,
    position: '7.7190° N, 5.3110° E',
  },
  Abia: {
    area: 6320,
    position: '5.4527° N, 7.5248° E',
  },
  Ebonyi: {
    area: 5670,
    position: '6.2649° N, 8.0137° E',
  },
  Imo: {
    area: 5530,
    position: '5.5720° N, 7.0588° E',
  },
  Anambra: {
    area: 4844,
    position: '6.2209° N, 6.9370° E',
  },
  Lagos: {
    area: 3345,
    position: '6.5227° N, 3.6218° E',
  },
};
export default landObject;
